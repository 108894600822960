<template>
  <div class="attendance-dashboard-container">
    <AttendanceHeader :show-campus-selector="true" :show-date-picker="attendanceMode===0"
                      :header-text="headerText"
                      :show-back="attendanceMode!==0"
                      @goBack="attendanceMode=0;removeQuery(['type','mode','id','name'])"></AttendanceHeader>
    <section class="section pt-0 px-0">
      <b-tabs expanded multiline  :destroy-on-hide="true" class="hidden-tabs" v-model="attendanceMode">
        <b-tab-item>
          <AttendanceDashboardLists @go="processGo"></AttendanceDashboardLists>
        </b-tab-item>
        <b-tab-item>
          <AttendanceCreateIndex @goBack="attendanceMode=0" @transferToEdit="attendanceMode = 2"
                                 :attendance_type="selected_attendance_type"
                                 :related_entity_id="selected_attendance_entity_id">
          </AttendanceCreateIndex>
        </b-tab-item>
        <b-tab-item>
          <AttendanceEditIndex @transferToCreate="attendanceMode=1" :attendance_type="selected_attendance_type"
                               :related_entity_id="selected_attendance_entity_id">

          </AttendanceEditIndex>
        </b-tab-item>
      </b-tabs>
    </section>

  </div>


</template>

<script>
import AttendanceHeader from "@/components/attendances/AttendanceHeader";
import AttendanceCreateIndex from "@/components/attendances/AttendanceCreateIndex";
import AttendanceDashboardLists from "@/components/attendances/AttendanceDashboardLists";
import AttendanceEditIndex from "@/components/attendances/AttendanceEditIndex";

export default {
  name: 'AttendanceIndex',
  components: {AttendanceHeader, AttendanceDashboardLists, AttendanceCreateIndex, AttendanceEditIndex},
  data() {
    return {
      attendanceMode: 0,
      selected_attendance_type: null,
      selected_attendance_entity_id: null,
      selected_attendance_name: null
    }
  },

  computed: {
    headerText() {
      if (this.attendanceMode === 0) {
        return 'ATTENDANCE'
      }
      return this.selected_attendance_name
    },


  },
  mounted() {
    if (this.$route.query.name) {
      this.selected_attendance_name = this.$route.query.name
    }
    if (this.$route.query.id) {
      this.selected_attendance_entity_id = parseInt(this.$route.query.id)
    }
    if (this.$route.query.type) {
      this.selected_attendance_type = parseInt(this.$route.query.type)
    }
    if (this.$route.query.mode) {
      this.attendanceMode = parseInt(this.$route.query.mode)
    }

  },
  methods: {
    processGo(attendance) {
      this.selected_attendance_name = attendance.name
      this.selected_attendance_entity_id = attendance.attendable_id
      this.selected_attendance_type = attendance.type_id
      this.setQuery({name: attendance.name, id: attendance.attendable_id, type: attendance.type_id})
      if (attendance.attendances_present === 0 && attendance.attendances_absent === 0) {
        this.setQuery({mode: 1})
        return this.attendanceMode = 1
      }
      this.setQuery({mode: 2})
      this.attendanceMode = 2
    },

  }

}

</script>
