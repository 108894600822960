<template>
  <div class="listitem mb-2">
    <div class="level is-mobile mobile-max-100">
      <div class="level-left has-pointer " :class="{'mobile-max-75':!edit_mode,'mobile-max-65':edit_mode}"
           @click.prevent="triggerModal">
        <div class="level-item"
        >
          <p class="image is-48x48">
            <img :src="user.avatar"
                 :alt="user.first_name + ' ' +user.last_name + ' ' + 'avatar'"
                 class="is-rounded"
            >
          </p>

        </div>
        <div class="level-item mobile-max-80">
          <p class="is-capitalized has-text-weight-semibold is-size-5 is-size-7-touch">{{ user.first_name }}
            {{ user.last_name }}</p>
        </div>
      </div>
      <div class="level-right mobile-max-25">
        <div class="level-item mr-0">
          <b-icon v-if="attendanceObject.is_tardy" class="has-text-warning" :icon="$tc('icons.circle')">

          </b-icon>
          <b-icon v-if="attendanceObject.early_release_at!==null" class="has-text-info" :icon="$tc('icons.circle')">

          </b-icon>
          <b-icon v-if="covidTest!==null"
                  :class="{'has-text-success':covidTest===1,'has-text-danger':covidTest===0}"
                  :icon="$tc('icons.medical-bag')"></b-icon>
        </div>
        <div class="level-item mr-0">
          <figure>
            <p class="has-text-centered mt-2">
              <b-field>
                <b-button

                    type="is-text"
                    @click.prevent="triggerModal"
                >
                  <b-icon class="has-text-grey-dark" :icon="$tc('icons.more')"></b-icon>
                </b-button>
              </b-field>
            </p>
          </figure>
        </div>
        <div class="level-item">
          <figure>
            <p class="has-text-centered mt-2">
              <b-field>
                <b-button
                    :inverted="attendanceObject.is_present===null"
                    :outlined="attendanceObject.is_present===null"
                    :loading="loadingAttendanceCheck"
                    :type="buttonType"
                    class="is-rounded"
                    :disabled="is_new?!can_create_attendance:!can_edit_attendance"
                    @click.prevent="toggleAttendance"
                >
                  <b-icon :class="{'is-invisible':attendanceObject.is_present===null}" v-if="buttonIcon"
                          :icon="buttonIcon"></b-icon>
                </b-button>
              </b-field>
            </p>
          </figure>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Attendance from "@/models/Attendance";
import AttendanceListModal from "@/components/attendances/AttendanceListModal";
import CovidAssessment from "@/models/CovidAssessment";


export default {
  name: "AttendanceListItem",
  props: {
    can_edit_attendance: {
      type: Boolean,
      default() {
        return false
      }
    },
    can_create_attendance: {
      type: Boolean,
      default() {
        return false
      }
    }, can_delete_attendance: {
      type: Boolean,
      default() {
        return false
      }
    },
    edit_mode: {
      type: Boolean,
      default() {
        return false
      }
    },
    present_now: {
      type: Boolean,
      default() {
        return false
      }
    },
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_edit: false,
        can_complete: false,
      }),
    },
    attended_date: {
      type: String, default: () => null
    },
    type_id: {
      type: Number,
      default() {
        return 1
      }
    },
    related_entity_id: {
      type: Number,
      required: true
    },

    attendance: {
      type: Object,
      default: () => null,
    },
    is_new: {
      type: Boolean, default: () => true
    },
    user: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {
      loadingAttendanceCheck: false,
      attendanceObject: {
        absent_reason_id: 1,
        attended_date: this.attended_date,
        user_id: this.user.id,
        is_present: null,
        is_tardy: 0,
        type_id: this.type_id,
        related_entity_id: this.related_entity_id,
        early_release_at: null,
      },
    };
  },

  computed: {
    covidTest(){
      if (this.attendanceObject.type_id!==1) {
        return null
      }
      if (CovidAssessment.query().where('taken_on',this.attended_date).where('user_id',this.user.id).exists()){
        let assessment = CovidAssessment.query().where('taken_on',this.attended_date).where('user_id',this.user.id).first()
        if (assessment.temperature>=38) {
          return 0
        }
      if (Object.values(assessment.symptoms).some(symptom=>symptom===true)){
        return 0
      }
      return 1

      }
      return null
    },
    buttonType() {
      if (this.attendanceObject.is_present === 1) {
        return 'is-success'
      }
      if (this.attendanceObject.is_present === 0) {
        return 'is-link'
      }
      return 'is-light'
    },
    buttonIcon() {
      if (this.attendanceObject.is_present === 0) {
        return 'tick'
      }
      return 'tick'
    },

  }, watch: {
    present_now(val) {
      if (val === true) {
        if (this.attendanceObject.is_present === null) {
          this.toggleAttendance()
        }
      }
    }
  },
  mounted() {
    if (!this.is_new) {
      this.syncAttendance()
    }
  },
  methods: {
    triggerModal() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          can_edit_attendance: this.can_edit_attendance,
          can_create_attendance: this.can_create_attendance,
          can_delete_attendance: this.can_delete_attendance,
          related_entity_id: this.related_entity_id,
          is_new: this.is_new,
          user: this.user,
          attendance: JSON.parse(JSON.stringify(this.attendanceObject))
        },
        component: AttendanceListModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "sync": () => {
            this.syncAttendance();
          },
          // "close": (persist) => {
          //
          //   if (persist === undefined) {
          //     console.log(persist)
          //     this.syncAttendance()
          //   }
          // }
        },
      });

    },
    syncAttendance() {
      this.attendanceObject = this.attendance
    },
    createAttendance() {
      this.loadingAttendanceCheck = true
      return Attendance.Store({...this.attendanceObject, ...{related_entity_id: this.related_entity_id}}).then(() => {
        this.syncAttendance()
        this.loadingAttendanceCheck = false

      }).catch(err => {
        this.handleError(err)
        this.syncAttendance()
        this.loadingAttendanceCheck = false

      })
    },
    updateAttendance() {
      return Attendance.Update({...this.attendanceObject, ...{related_entity_id: this.related_entity_id}}).then(() => {
        this.syncAttendance()
      }).catch(err => {
        this.handleError(err)
        this.syncAttendance()
      })
    },
    toggleAttendance() {
      if (this.attendanceObject.is_present === null) {
        this.attendanceObject.is_present = 1
      } else {
        this.attendanceObject.is_present = !this.attendanceObject.is_present
        if (this.attendanceObject.is_present === false && this.attendanceObject.absent_reason_id === null) {
          this.attendanceObject.absent_reason_id = 1
        }
      }
      if (this.is_new) {
        this.createAttendance()
      } else {
        this.loadingAttendanceCheck = true
        this.updateAttendance().then(() => {
          this.loadingAttendanceCheck = false
        })
      }


    },


  },
};
</script>
