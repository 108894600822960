<template>

  <div class="columns is-centered pt-4">
    <div class="column is-8">

      <div v-if="homeRoomAttendances.length>0">
        <h3 class="subtitle is-size-3 is-size-4-touch">My HomeClasses</h3>

        <AttendanceDashboardItem @goTo="processGo" v-for="classItem in homeRoomAttendances" :class-item="classItem"
                                 :key="classItem.id"></AttendanceDashboardItem>
      </div>

      <div class="mt-4" v-if="serviceAttendances.length>0">
        <h3 class="subtitle is-size-3 is-size-4-touch">Services</h3>

        <AttendanceDashboardItem @goTo="processGo" v-for="classItem in serviceAttendances" :class-item="classItem"
                                 :key="classItem.id"></AttendanceDashboardItem>
      </div>
      <div class="mt-4" v-if="subjectAttendances.length>0">
        <h3 class="subtitle is-size-3 is-size-4-touch">Subjects</h3>

        <AttendanceDashboardItem @goTo="processGo" v-for="classItem in subjectAttendances" :class-item="classItem"
                                 :key="classItem.id"></AttendanceDashboardItem>
      </div>
      <div class="mt-4" v-if="extramuralAttendances.length>0">
        <h3 class="subtitle is-size-3 is-size-4-touch">Extramurals</h3>

        <AttendanceDashboardItem @goTo="processGo" v-for="classItem in extramuralAttendances" :class-item="classItem"
                                 :key="classItem.id"></AttendanceDashboardItem>
      </div>
    </div>
  </div>


</template>

<script>
import {mapState, mapGetters} from 'vuex'
import AttendanceDashboardItem from "@/components/attendances/AttendanceDashboardItem";
import AttendanceDashboard from "@/models/AttendanceDashboard";


export default {
  name: 'AttendanceDashboardLists',
  components: {AttendanceDashboardItem},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('attendance', [
      'attendance_date_filter',
    ]),
    ...mapState('campus', ['selected_campus_id']),
    ...mapState('attendance', ['attendance_date_truth']),
    homeRoomAttendances() {
      return AttendanceDashboard.query().where('type_id', 1).orderBy('name').get()
    }, serviceAttendances() {
      return AttendanceDashboard.query().where('type_id', 2).orderBy('name').get()
    }, subjectAttendances() {
      return AttendanceDashboard.query().where('type_id', 3).orderBy('name').get()
    }, extramuralAttendances() {
      return AttendanceDashboard.query().where('type_id', 4).orderBy('name').get()
    }
  },
  watch: {
    selected_campus_id() {
      this.getDashboard()
    },
    attendance_date_truth() {
      this.getDashboard()
    }
  },
  methods: {
    processGo(attendance) {
      this.$emit('go', attendance)
    },
    getDashboard() {
      this.$store.dispatch('loader/show')
      AttendanceDashboard.Fetch({
        date: this.attendance_date_filter,
        campus_id: this.$store.state.campus.selected_campus_id
      }).then(() => {
        this.$store.dispatch('loader/hide')

      }).catch(err => {
        this.handleError(err)
      })

    }
  }
  ,
  mounted() {
    this.getDashboard()
  }
}

</script>
