<template>
  <div class="attendance-list-modal">

    <div class="modal-card box is-relative" style="width: auto">
      <button
          type="button"
          style="position:absolute; top: 0.5rem;right: 0.5rem"
          class="delete is-pulled-right"
          @click="$emit('close')"/>
      <section class="modal-card-body">
        <div class="columns is-mobile is-centered">
          <div class="column is-narrow pb-0 has-text-centered">

            <p ref="name" class="image is-64x64 ">
              <img :src="user.avatar"
                   :alt="user.first_name + ' ' +user.last_name + ' ' + 'avatar'"
                   class="is-rounded"
              >
            </p>
          </div>
        </div>
        <div class="columns is-mobile is-centered">
          <div class="column is-narrow has-text-centered">
            <p class="is-capitalized has-text-weight-semibold is-size-5">{{ user.first_name }} {{ user.last_name }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-centered">
          <div class="column has-text-centered">
            <b-field>
              <b-button
                  :outlined="attendanceObject.is_present!==0"
                  :type="'is-link'"
                  class="is-rounded"
                  @click="toggleAbsent"
              >
                <b-icon class="has-text-white"
                        :icon="$tc('icons.tick')"></b-icon>
              </b-button>
            </b-field>
            <a @click="activeTab=0">Absent</a>
          </div>
          <div class="column has-text-centered">
            <b-field>
              <b-button
                  :outlined="attendanceObject.is_tardy!==1"
                  :type="'is-warning'"
                  class="is-rounded"
                  @click="toggleTardy"
              >
                <b-icon class="has-text-white"
                        :icon="$tc('icons.tick')"></b-icon>
              </b-button>
            </b-field>
            <a @click="activeTab=1">Tardy</a>
          </div>
          <div class="column has-text-centered">
            <b-field>
              <b-button
                  :outlined="attendanceObject.early_release_at===null"
                  :type="'is-info'"
                  class="is-rounded"
                  @click="toggleEarlyRelease"
              >
                <b-icon class="has-text-white"
                        :icon="$tc('icons.tick')"></b-icon>
              </b-button>
            </b-field>
            <a @click="activeTab=2">Early Release</a>
          </div>
        </div>
        <div v-if="attendance.type_id ===1" class="mb-4">
          <p class="has-text-weight-bold">
            Covid Assessment
            <CovidAssessmentForm v-if="loadedCovidAssessments"
                                 :default_taken_on="attendanceObject.attended_date"
                                 :show-buttons="false"
                                 :is_required="attendanceObject.is_present===1"
                                 :edit="!!covidAssessment"
                                 :covid-assessment="covidAssessment"
                                 @submitSuccess="submit"
                                 @submitFail="submitCovidAssessment = false"
                                 :submit_flip="submitCovidAssessment"
                                 :default_user="user.id"
                                 :can-create="!!$store.getters['entities/user-permissions/find']('create covid assessments')"
                                 :can-edit="!!$store.getters['entities/user-permissions/find']('edit covid assessments')"
                                 :can-delete="false"
            />
            <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>
          </p>
        </div>

        <b-tabs expanded multiline  v-model="activeTab" class="hidden-tabs">
          <b-tab-item>
            <p class="has-text-weight-bold">Absent Reason</p>
            <b-field>
              <b-radio native-value="1" v-model="attendanceObject.absent_reason_id">
                No Reason
              </b-radio>

            </b-field>
            <b-field>
              <b-radio native-value="2" v-model="attendanceObject.absent_reason_id">
                Sick
              </b-radio>
            </b-field>
          </b-tab-item>
          <b-tab-item>

          </b-tab-item>
          <b-tab-item>
            <b-timepicker v-model="release_time" inline></b-timepicker>

          </b-tab-item>
        </b-tabs>
        <b-field expanded position="is-centered">
          <b-input expanded type="textarea" placeholder="Notes" v-model="attendanceObject.note"></b-input>
        </b-field>
        <b-field grouped position="is-centered">
          <b-field v-if="!is_new" position="is-centered" class="has-text-centered">
            <b-button :disabled="!can_delete_attendance" @click="startRemove" type="is-danger">
              Remove
            </b-button>
          </b-field>
          <b-field position="is-centered" class="has-text-centered">
            <b-button :disabled="is_new?!can_create_attendance:!can_edit_attendance" @click="covidSubmit"
                      type="is-success">
              Save
            </b-button>
          </b-field>
        </b-field>
      </section>
      <div style="width: 0;overflow:hidden;margin: 0;height: 0"><!--suppress HtmlFormInputWithoutLabel -->
        <input ref="sacrificial_input"
               style="height: 0;width: 0;opacity: 0"
               type="button">
      </div>
    </div>
  </div>

</template>
<script>

import {getHours, getMinutes, setHours, setMinutes} from 'date-fns'
import Attendance from "@/models/Attendance";
import CovidAssessmentForm from "@/components/covidAssessments/CovidAssessmentForm";
import CovidAssessment from "@/models/CovidAssessment";

export default {
  name: 'AttendanceListModal',
  components: {CovidAssessmentForm},
  data() {
    return {
      activeTab: 0,
      loadingAbsent: false,
      loading: false,
      loadedCovidAssessments: false,
      attendanceObject: {},
      timeHold: this.attendance.early_release_at,
      submitCovidAssessment: false
    }
  },
  computed: {
    covidAssessment() {
      return CovidAssessment.query().where('user_id', this.attendanceObject.user_id).where('taken_on', this.attendanceObject.attended_date).first()
    },
    release_time: {
      set(val) {
        this.timeHold = val
        let big = new Date(this.$store.getters["attendance/attendance_date_filter"])
        big = setHours(big, getHours(new Date(val)))
        big = setMinutes(big, getMinutes(new Date(val)))
        this.attendanceObject.early_release_at = big
      }, get() {
        return new Date(this.attendanceObject.early_release_at)
      }
    }
  },
  watch: {
    attendance() {
      this.attendanceObject = JSON.parse(JSON.stringify(this.attendance))
    }
  },
  mounted() {
    this.attendanceObject = JSON.parse(JSON.stringify(this.attendance))
    this.attendanceObject.absent_clicked = 0
    if (this.attendanceObject.type_id === 1) {
      this.loadedCovidAssessments = true

      // this.loading = true
      // CovidAssessment.FetchAll({page: 1, limit: 1}, {
      //   user_id: this.attendanceObject.user_id,
      //   date: this.attendanceObject.attended_date
      // }).then(() => {
      //       this.loading = false
      //       this.loadedCovidAssessments = true
      //     }
      // )
    }
  },
  methods: {
    startRemove() {
      this.$buefy.dialog.confirm({
        title: `Deleting attendance record`,
        confirmText: `Delete record`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this record?`,
        onConfirm: () =>
            Attendance.Delete(this.attendanceObject.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Attendance deleted!`);
                  this.$emit('close', false)
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    covidSubmit() {
      if (this.attendanceObject.type_id === 1 && this.attendanceObject.is_present===1) {
        this.submitCovidAssessment = true
      } else {
        this.submit()
      }
    },
    submit() {
      this.submitCovidAssessment = false
      if (!this.is_new) {
        Attendance.Update({...this.attendanceObject, ...{related_entity_id: this.related_entity_id}}).then(() => {
          this.$emit('sync')
          this.$emit('close', false)
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        if ((this.attendanceObject.is_tardy===0)&&(this.attendanceObject.early_release_at===null) && (this.attendanceObject.absent_clicked!==1) && (this.attendanceObject.is_present!==1)) {
          this.attendanceObject.is_present = 1
        }
        return Attendance.Store({...this.attendanceObject, ...{related_entity_id: this.related_entity_id}}).then(() => {
          this.$emit('sync')
          this.$emit('close', false)

        }).catch(err => {
          this.handleError(err)


        })
      }

    },
    toggleEarlyRelease() {
      this.$refs.sacrificial_input.focus()
      if (this.attendanceObject.early_release_at === null) {
        let big = new Date(this.$store.getters["attendance/attendance_date_filter"])
        big = setHours(big, getHours(new Date()))
        big = setMinutes(big, getMinutes(new Date()))
        this.attendanceObject.early_release_at = big
        this.attendanceObject.is_present = 1
        this.activeTab = 2
      } else {
        this.attendanceObject.early_release_at = null
      }
    },
    toggleTardy() {
      this.$refs.sacrificial_input.focus()

      if (this.attendanceObject.is_tardy !== 1) {
        this.attendanceObject.is_tardy = 1
        this.attendanceObject.is_present = 1
        this.activeTab = 1
      } else {
        this.attendanceObject.is_tardy = 0
      }
    },
    toggleAbsent() {
      this.$refs.sacrificial_input.focus()
      if (this.attendanceObject.is_present !== 0) {
        this.attendanceObject.is_present = 0
        this.activeTab = 0
        this.attendanceObject.absent_clicked=1
      } else {
        this.attendanceObject.is_present = 1
      }
    }
  },
  props: {
    can_edit_attendance: {
      type: Boolean, default() {
        return false
      }
    }, can_create_attendance: {
      type: Boolean, default() {
        return false
      }
    }, can_delete_attendance: {
      type: Boolean, default() {
        return false
      }
    },
    related_entity_id: {
      type: Number,
      required: true
    },
    is_new: {
      type: Boolean,
      default() {
        return false
      }
    },
    user: {
      type: Object,
      required: true
    },
    attendance: {
      type: Object,
      required: true
    }
  }
}
</script>
