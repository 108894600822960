<template>
  <div class="level px-4 py-3 attendance-dashboard-item is-mobile">
    <div class="level-left">
      <div class="level-item has-pointer" @click="$emit('goTo',classItem)">
        <p class="is-capitalized dark-text has-text-weight-bold is-size-4">{{ classItem.name }}</p>
      </div>
    </div>
    <div class="level-right">
      <div v-if="classItem.attendances_present === 0 && classItem.attendances_absent === 0"
           class="level-item stack-text line-height-revert has-text-centered">
        <div>
          <p class="dark-text has-text-weight-bold is-size-4">{{ classItem.users_count }}</p>
        </div>
        <div><p class="is-size-7">Students</p></div>
      </div>
      <div v-else class="level-item">
        <div class="columns is-centered is-vcentered is-mobile">

          <div class="little-circle is-relative column  mr-2 is-success"
               v-if="classItem.attendances_present>0">
            <p class="has-text-centered has-text-success">
              {{ classItem.attendances_present }}
            </p></div>
          <div class="little-circle is-relative column  mr-2 is-link"
               v-if="classItem.attendances_absent>0">
            <p class="has-text-centered has-text-link"> {{ classItem.attendances_absent }}
            </p></div>
          <div v-if="remainingCount>0" class="little-circle mr-2 is-relative column  is-grey">
            <p class="has-text-centered has-text-grey"> {{ remainingCount }}
            </p></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "AttendanceDashboardItem",
  props: {

    classItem: {
      type: Object,
      default: () => null,
    },

  },
  computed: {
    remainingCount() {
      return this.classItem.users_count - this.classItem.attendances_present - this.classItem.attendances_absent

    }
  },
  data() {
    return {};
  },

  mounted() {

  },
};
</script>
