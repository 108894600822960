<template>
  <section class="hero attendance-header is-large">
    <div class="hero-body pt-3 pb-4">
      <div class="container has-text-centered">
        <div class="columns is-centered">

          <div class="column is-relative has-text-centered">
            <span
                class="is-capitalized has-text-centered title has-text-white">
          {{ headerText }}
        </span>
            <div style="top: 0.75rem;left:0;position: absolute">
              <b-field v-if="showBack"><a
                  class="card-header-icon has-text-white  px-0 py-0"
                  @click="$emit('goBack')"
              >
                <b-icon :icon="$tc('icons.chevron-left')" size="is-medium"/>
              </a></b-field>
            </div>
            <div style="top:0.75rem;right: 0;position: absolute" v-if="campuses.length>1 && showCampusSelector">
              <b-field
                  class="is-hidden-mobile"

                  type="is-primary">
                <b-select v-model="CampusSelection" class="is-borderless has-text-primary "
                          placeholder="Select a campus">
                  <option value="All" v-if="canAll">All</option>
                  <option
                      v-for="campus in campuses"
                      :value="campus.id"
                      :key="campus.id">
                    {{ campus.name }}
                  </option>
                </b-select>

              </b-field>
              <b-field class="is-hidden-tablet"><a

                  class="card-header-icon has-text-white px-0 py-0"
                  @click="campusModal=true"
              >
                <b-icon icon="menu" size="is-medium"/>
              </a></b-field>
              <b-modal :width="200" v-model="campusModal" scroll="keep">
                <div class="card">
                  <div class="card-content has-background-primary">
                    <b-field
                        label="Campus"

                        type="is-primary">
                      <b-select v-model="CampusSelection" class="is-borderless has-text-primary "
                                placeholder="Select a campus">
                        <option value="All" v-if="canAll">All</option>
                        <option
                            v-for="campus in campuses"
                            :value="campus.id"
                            :key="campus.id">
                          {{ campus.name }}
                        </option>
                      </b-select>

                    </b-field>
                  </div>
                </div>
              </b-modal>

            </div>
          </div>
        </div>

        <div v-if="showDatePicker" class="pb-3 columns is-centered">
          <div class="column is-6">
            <DatePicker @set="val=>$store.dispatch('attendance/set_attendance_date',val)"
                        @back="$store.dispatch('attendance/back_days')"
                        @forward="$store.dispatch('attendance/forward_days')"
                        :date="$store.state.attendance.attendance_date_truth"></DatePicker>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script>

import DatePicker from "@/components/dates/DatePicker";
import Campus from "@/models/Campus";
import {mapState} from 'vuex'

export default {
  name: "AttendanceHeader",
  components: {
    DatePicker
  },
  data() {
    return {
      loaded: false,
      showMenu: false,
      noCampuses: false,
      campusModal: false,
    }
  },
  mounted() {
    Campus.FetchAll({page: 1, limit: 100}).then(response => {
      if (response.response.data.data.length > 0) {
        let campusId = parseInt(this.$store.state.campus.selected_campus_id !== null ? this.$store.state.campus.selected_campus_id : response.response.data.data[0].id)
        this.$store.dispatch('campus/selectCampus', campusId)
        Campus.FetchById(campusId, ['phases']).then(() => {
          this.loaded = true
          this.$emit('loaded')

        })
      } else {
        this.noCampuses = true
        this.$emit('loaded')
        this.loaded = true
      }
    })
  },
  computed: {
    ...mapState('campus', ['selected_campus_id']),
    CampusSelection: {
      get() {
        return this.selected_campus_id
      }, set(id) {
        this.campusModal = false
        this.$emit('campus-selected', id)
        if (id !== 'All') {
          this.$store.dispatch('campus/selectCampus', id)

        } else {
          this.$store.dispatch('campus/setAll', true)
        }
      }
    }
    ,
    campuses() {
      return Campus.query().orderBy('ordinality').get()
    },
  },
  props: {
    showDatePicker: {
      type: Boolean,
      default() {
        return true
      }
    },
    headerText: {
      type: String,
      default() {
        return 'ATTENDANCE'
      }
    },
    showCampusSelector: {
      type: Boolean,
      default() {
        return false
      }
    }, showBack: {
      type: Boolean,
      default() {
        return true
      }
    },
    canAll: {
      type: Boolean, default() {
        return false
      }
    },

  },

}
</script>