<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;min-height: 400px">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Add {{ $tc('Learner',1) }}</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field :label="$tc('Learner',1)">
          <UsersFilter :persist-search="false" :role="'learner'" :dropdown-direction="'auto'"
                       :type="'autocomplete'" @selected="selectUser"
                       :status_id="2"
                       :year="year"
                       :campus_id="attendance.campus_id"></UsersFilter>
        </b-field>
      </section>

    </div>
  </form>
</template>

<script>

import UsersFilter from "@/components/panelled-dash/UsersFilter";

export default {
  name: "Adhoc",
  components: {UsersFilter},
  data() {
    return {
      attendance: {
        user_id: null,
        campus_id: this.campus_id,
      }
    }
  },
  methods: {

    selectUser(user) {
      this.attendance.user_id = user.id
      this.submit()
    },
    submit() {
      this.$emit('select_learner', this.attendance.user_id)
      this.$emit('close')
    }
  }

  ,
  props: {
    year: {
      type: Number, default() {
        return new Date().getFullYear()
      }
    },
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    },


    inModal: {
      type: Boolean,
      default
          () {
        return true
      }
    }
  }
}
</script>

