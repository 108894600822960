<template>
  <div class="attendance-create-container">
    <section class="container header-container pt-5 pb-5">
      <div class="columns pt-2 pb-2 is-centered">
        <div class="column is-8">
          <div class="columns is-vcentered is-centered is-mobile">
            <div class="column is-narrow-mobile">
              <DatePicker :type="'light'"
                          @set="val=>$store.dispatch('attendance/set_attendance_date',val)"
                          @back="$store.dispatch('attendance/back_days')"
                          @forward="$store.dispatch('attendance/forward_days')"
                          :date="$store.state.attendance.attendance_date_truth"></DatePicker>
            </div>
            <div class="column is-narrow ">
              <b-button

                  :loading="loadingAllPresent"
                  :type="{'is-success':loadedAllPresent}"
                  class="is-rounded"
                  :disabled="!$store.getters['entities/user-permissions/find']('create attendances')"
                  @click.prevent="setAllPresent"
              >
                <b-icon :icon="$tc('icons.tick')"></b-icon>
              </b-button>
            </div>
            <div v-if="attendance_type===1" class="column is-narrow ">
              <b-button

                  :loading="loadingAllNotCovid"
                  :type="{'is-success':loadedAllNotCovid}"
                  class="is-rounded"
                  :disabled="!$store.getters['entities/user-permissions/find']('create covid assessments') || !$store.getters['attendance/is_today'] ||loadedAllNotCovid||loadingAllNotCovid"
                  @click.prevent="setAllNotCovid"
              >
                <b-icon :icon="$tc('icons.medical-bag')"></b-icon>
              </b-button>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="pt-4" v-if="ad_hoc || attendance_type!==1">
      <div class="columns is-centered">
        <div class="column is-narrow" v-if="attendance_type!==1">
          <b-field group-multiline grouped position="is-centered">
            <b-field position="is-centered">
              <b-select placeholder="Phase" v-model="phase_id">
                <option :value="null">All</option>
                <option v-for="phase in phases" :key="phase.id" :value="phase.id">{{ phase.name }}</option>
              </b-select>
            </b-field>

            <b-field position="is-centered">
              <b-select :loading="loadingPhaseSelect" :disabled="phase_id===null" placeholder="Stage"
                        v-model="stage_id">
                <option :value="null">All</option>
                <option v-for="stage in stages" :key="stage.id" :value="stage.id">{{ stage.name }}</option>
              </b-select>
            </b-field>
            <b-field position="is-centered">
              <b-select :loading="loadingPhaseSelect" :disabled="phase_id===null" placeholder="Homeclass"
                        v-model="home_class_id">
                <option :value="null">All</option>
                <option v-for="homeclass in homeclasses" :key="homeclass.id" :value="homeclass.id">{{
                    homeclass.name
                  }}
                </option>
              </b-select>
            </b-field>
            <b-field v-if="attendance_type===3" position="is-centered">
              <b-select placeholder="Class"
                        v-model="class_group_id">
                <option v-for="classGroup in classGroups" :key="classGroup.id" :value="classGroup.id">{{
                    classGroup.name
                  }}
                </option>
              </b-select>
            </b-field>
          </b-field>
        </div>

        <div class="column is-narrow" v-if="ad_hoc">
          <b-field position="is-centered" class="has-text-centered">
            <b-button @click="addLearner()" :icon-right="$tc('icons.create')" type="is-primary">
              Add {{ $tc('learner', 1) }} ad hoc
            </b-button>
          </b-field>
        </div>
      </div>
    </section>
    <section v-if="loaded" class="section  px-1">

      <div class="columns is-centered">
        <div class="column is-8">
          <AttendanceListItem v-for="attendance_user in attendance_users"
                              :present_now="set_present_bulk"
                              :type_id="attendance_type"
                              :user="attendance_user"
                              :can_edit_attendance="!!$store.getters['entities/user-permissions/find']('edit attendances')"
                              :can_create_attendance="!!$store.getters['entities/user-permissions/find']('create attendances')"
                              :can_delete_attendance="!!$store.getters['entities/user-permissions/find']('delete attendances')"
                              :related_entity_id="related_entity_id"
                              :is_new="attendance_user.attendances.length===0"
                              :attendance="attendance_user.attendances.length>0?attendance_user.attendances[0]:null"
                              :attended_date="$store.getters['attendance/attendance_date_filter']"
                              :key="attendance_user.id"></AttendanceListItem>

          <b-field position="is-centered" class="has-text-centered">
            <b-button :disabled="!!!$store.getters['entities/user-permissions/find']('create attendances')"
                      @click="$emit('goBack')" type="is-success">
              Save
            </b-button>
          </b-field>
        </div>
      </div>

    </section>
  </div>
</template>

<script>

import AttendanceListItem from "@/components/attendances/AttendanceListItem";

import DatePicker from "@/components/dates/DatePicker";
import User from "@/models/User";
import Attendance from "@/models/Attendance";

import {mapState} from 'vuex'
import AttendanceAdhocForm from "@/components/attendances/AttendanceAdhocForm";
import Service from "@/models/Service";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import HomeClass from "@/models/HomeClass";
import ClassGroup from "@/models/ClassGroup";
import CovidAssessment from "@/models/CovidAssessment";

export default {
  name: 'AttendanceCreateIndex',
  components: {DatePicker, AttendanceListItem},
  data() {
    return {
      loadingAllPresent: false,
      loadedAllPresent: false,
      loadingAllNotCovid: false,
      loaded: false,
      set_present_bulk: false,
      ad_hoc: false,
      phase_id: null,
      stage_id: null,
      class_group_id: null,
      home_class_id: null,
      loadingPhaseSelect: null,
      attendance_user_hold: null,

    }
  },
  props: {
    attendance_type: {
      type: Number,
      required: true
    }, related_entity_id: {
      type: Number,
      required: true
    },
  },
  computed: {
    have_attendance_users() {
      return this.attendance_users.filter(user => user.attendances.length !== 0)
    },
    absent_attendance_users() {
      return this.have_attendance_users.filter(user => !user.attendances[0].is_present)
    },
    loadedAllNotCovid(){
      return this.covidAssessments.length >= (this.attendance_users.length-this.absent_attendance_users.length)
    },
    covidAssessments() {
      return CovidAssessment.query().where('taken_on', this.$store.getters["attendance/attendance_date_filter"]).get()
    },
    covidAssessmentUsers() {
      return this.covidAssessments.map(assessment => {
        return assessment.user_id
      })
    },
    newCovidAssessmentUsers() {
      return this.attendance_users.filter(x => {
        if (x.attendances.length>0) {
          return !x.attendances.some(attendance=>attendance.is_present===0)
        }
        return !this.covidAssessmentUsers.includes(x.id)
      })
    },
    classGroups() {
      return ClassGroup.query().where('subject_id', this.related_entity_id).get()
    },
    phases() {
      return Phase.query().get()
    },
    stages() {
      return Stage.query().where('phase_id', this.phase_id).get()
    },
    homeclasses() {
      return HomeClass.query().where('phase_id', this.phase_id).get()
    },
    filters() {
      return {

        ...(this.phase_id
            ? {
              phase_id: this.phase_id,
            }
            : {}),
        ...(this.stage_id
            ? {
              stage_id: this.stage_id,
            }
            : {}),
        ...(this.home_class_id
            ? {
              home_class_id: this.home_class_id,
            }
            : {}), ...(this.class_group_id
            ? {
              class_group_id: this.class_group_id,
            }
            : {}), attendance_type: this.attendance_type,
        related_entity_id: this.related_entity_id, date: this.$store.getters["attendance/attendance_date_filter"]


      };
    },

    ...mapState('attendance', ['attendance_date_truth']),
    attendance_users() {
      if (this.attendance_user_hold === null) {
        return User.query().with('attendances').with('enrolments').with('enrolments.phase').with('enrolments.stage').with('enrolments.home_class').orderBy('first_name').get()
      }
      return this.attendance_user_hold
    },
  },
  watch: {
    filters() {
      this.fetchAttendances()
    },
    phase_id() {
      this.stage_id = null
      this.home_class_id = null
      // if (newVal !== null) {
      //   this.loadingPhaseSelect = true
      //   Promise.all([Stage.FetchAll({page: 1, limit: 999}, {phase_id: newVal}),
      //     HomeClass.FetchAll({page: 1, limit: 999}, {phase_id: newVal})]).finally(() => {
      //     this.loadingPhaseSelect = false
      //   })
      //
      // }
    },
    attendance_date_truth() {
      this.set_present_bulk = false
      this.loadedAllPresent = false
      this.$store.dispatch('loader/show')
      this.loaded = false
      User.deleteAll()
      Attendance.deleteAll()
      User.FetchAttendanceUsers({page: 1, limit: 999}, {
        attendance_type: this.attendance_type,
        related_entity_id: this.related_entity_id, date: this.$store.getters["attendance/attendance_date_filter"]
      }, ['attendances']).then(({entities}) => {
        if (Object.prototype.hasOwnProperty.call(entities, 'attendances')) {
          this.$emit('transferToEdit')
        }
        this.loaded = true
        this.$store.dispatch('loader/hide')
      }).catch(err => {
        this.$store.dispatch('loader/hide')

        this.handleError(err)
      })
    }
  },
  methods: {
    fetchAttendances() {
      this.$store.dispatch('loader/show')
      this.attendance_user_hold = this.attendance_users

      User.deleteAll()
      User.FetchAttendanceUsers({page: 1, limit: 999},
          this.filters
          , ['attendances']).then(() => {
        this.attendance_user_hold = null
        if (this.attendance_type === 1) {
          CovidAssessment.FetchAll({page: 1, limit: 999}, {
            date: this.$store.getters['attendance/attendance_date_filter'], user_ids:
                this.attendance_users.map(user => {
                  return user.id
                })
          })
        }

        this.loaded = true
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      })
    },

    handle(learner_id) {
      this.$store.dispatch('loader/show')
      User.FetchById(learner_id, ['attendances']).then(() => {
        this.$store.dispatch('loader/hide')
      }).catch(err => {
        this.$store.dispatch('loader/hide')

        this.handleError(err)
      })
    },
    addLearner() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          year: this.$store.state.attendance.attendance_date_truth.getFullYear()
        },
        component: AttendanceAdhocForm,
        fullScreen: false,
        hasModalCard: false,
        trapFocus: true,
        events: {
          "select_learner": (learner_id) => {
            this.handle(learner_id)
          },
        },
      });

    },
    setAllPresent() {
      if (!this.loadedAllPresent) {
        this.loadingAllPresent = true
        this.set_present_bulk = true
        setTimeout(() => {
          this.loadedAllPresent = true;
          this.loadingAllPresent = false
        }, 500)
      }
    },
    setAllNotCovid() {
      if (!this.loadedAllNotCovid) {
        this.loadingAllNotCovid = true
        // let covidAssessmentUsers = this.attendance_users.filters()
        let symptoms = JSON.parse(JSON.stringify(this.$store.state.covid.empty_object))

        CovidAssessment.StoreBulk(this.newCovidAssessmentUsers.map(user => {
              return {
                user_id: user.id,
                temperature: 36,
                taken_on: this.$store.getters['attendance/attendance_date_filter'],
                symptoms: symptoms
              }
            })
        ).catch(err => {
          this.handleError(err)
        }).finally(() => {
          CovidAssessment.FetchAll({page: 1, limit: 999}, {
            date: this.$store.getters['attendance/attendance_date_filter'], user_ids:
                this.attendance_users.map(user => {
                  return user.id
                })
          }).then(() => {
            this.loadingAllNotCovid = false

          })
        })
      }
    }
  }
  ,
  mounted() {
    this.$store.dispatch('loader/show')
    User.deleteAll()
    Attendance.deleteAll()
    CovidAssessment.deleteAll()
    if (
        this.attendance_type !== 1
    ) {
      if (this.attendance_type === 3) {
        ClassGroup.deleteAll()
        ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: this.related_entity_id})
      }
      Phase.deleteAll()
      Phase.FetchAll({page: 1, limit: 999}, {
        campus_id: this.$store.state.campus.selected_campus_id,
        year: this.$store.state.dates.year
      })
      Stage.FetchAll({page: 1, limit: 999}, {
        campus_id: this.$store.state.campus.selected_campus_id,
        year: this.$store.state.dates.year
      })
      HomeClass.FetchAll({page: 1, limit: 999}, {
        campus_id: this.$store.state.campus.selected_campus_id,
        year: this.$store.state.dates.year
      })
      if (this.attendance_type === 2) {
        Service.FetchById({id: this.related_entity_id}).then(result => {
          this.ad_hoc = result.response.data.data.attributes.has_ad_hoc_attendants === 1

        })
      }
    }
    this.fetchAttendances()
  }
}
</script>
